<template>
  <v-container id="dashboard-view" fluid tag="section">
    <v-row>
      <v-col cols="12">
        <v-toolbar color="primary " dark class="mx-4">
          <v-select
            :items="hrSuppliers"
            v-model="company_id"
            label="Select supplier"
            item-text="name"
            auto-select-first
            item-value="id"
            chips
            name="Select supplier"
            hide-selected
            hide-details
            solo
            light
            class="font-weight-medium mx-3"
          >
          </v-select>
        </v-toolbar>
      </v-col>
      <kpi-list v-if="kpis" :kpis="kpis"></kpi-list>
      <v-col cols="12" md="12">
        <dashboard-card color="light-blue darken-1" full-header>
          <template #heading>
            <div class="pa-8 white--text">
              <div class="text-h4 font-weight-light">
                HR Suppliers
              </div>
            </div>
          </template>
          <v-card-text>
            <v-data-table
              :headers="hrSuppliersHeaders"
              :items="hrSuppliers"
              :options="hrSuppliersPagination"
              :items-per-page.sync="hrSuppliersPagination.rowsPerPage"
              :server-items-length.sync="hrSuppliersTotal"
              :page.sync="hrSuppliersPagination.page"
              :loading="loading"
            />
          </v-card-text>
        </dashboard-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "DashboardView",
  components: {
    DashboardCard: () => import("@/components/core/cards/DashboardCard"),
    KpiList: () => import("@/components/core/cards/DashboardKpi")
  },
  computed: {
    ...mapGetters({
      hrSuppliers: "dashboard/hrSuppliers",
      hrSuppliersTotal: "dashboard/hrSuppliersTotal",
      kpis: "companies/kpis",
      loading: "dashboard/loading",
      hrSuppliersHeaders: "dashboard/hrSuppliersHeaders"
    }),
    hrSuppliers: {
      get() {
        return this.$store.getters["dashboard/hrSuppliers"];
      },
      set() {
        this.$store.dispatch("dashboard/getHrSuppliers");
      }
    },
    hrSuppliersPagination: {
      get() {
        return this.$store.getters["dashboard/pagination"];
      },
      set(value) {
        this.$store.dispatch("dashboard/setPagination", value);
      }
    }
  },
  watch: {
    pagination: {
      handler() {
        this.getHrSupplier();
      },
      deep: true
    },
    company_id: {
      handler() {
        this.getKpis();
      },
      deep: true
    }
  },
  methods: {
    async getHrSupplier() {
      let _this = this;
      await _this.$store.dispatch("dashboard/getHrSuppliers").then(() => {
        _this.company_id = _this.hrSuppliers[0].id;
      });
    },
    async getKpis() {
      let _this = this;
      await _this.$store.dispatch("companies/getKPI", this.company_id);
    }
  },
  created() {
    this.getHrSupplier();
  },
  data: () => ({
    company_id: null
  })
};
</script>
